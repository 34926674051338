export const required = value => value.trim() !== '';

export const length = config => value => {
  let isValid = true;
  if (config.min) {
    isValid = isValid && value.trim().length >= config.min;
  }
  if (config.max) {
    isValid = isValid && value.trim().length <= config.max;
  }
  return isValid;
};

export const email = value =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value
  );

export const validComplexPassword = value => {
  //Minimum 8 maximum 20 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
    value
  );
}

export const acceptImageType = ['png', 'jpg', 'jpeg', 'webp', 'gif'];
export const acceptVideoType = ['mp4', 'webm'];

export const acceptableFile = (value) => {
  const fileType = value.split('.')[value.split('.').length -1].toLowerCase();
  console.log(fileType);

  // const acceptTypes = acceptImageType;
  const acceptTypes = acceptImageType.concat(acceptVideoType);
  
  let matchType;
  if (fileType) {
    matchType = acceptTypes.find(type => type === fileType.toLowerCase());
  }
  console.log(matchType);

  if (
    matchType 
  ) {
    return true;
  } else {
    return false;
  }
}


export const acceptableFileSize = file => {
  const fileSize = file.size;
  // console.log('fileSize', fileSize)
  // if (fileSize > 1000 * 200) {
  if (fileSize > 10**6 * 25) {
    return false;
  }
  
  return true;
}


export const acceptable150x500File = (value) => {
  const fileType = value.split('.')[value.split('.').length -1].toLowerCase();
  console.log(fileType);

  // const acceptTypes = acceptImageType;
  const acceptTypes = acceptImageType;
  
  let matchType;
  if (fileType) {
    matchType = acceptTypes.find(type => type === fileType.toLowerCase());
  }
  console.log(matchType);

  if (
    matchType 
  ) {
    return true;
  } else {
    return false;
  }
};


export const acceptable150x500FileSize = file => {
  const fileSize = file.size;
  // console.log('fileSize', fileSize)
  // if (fileSize > 1000 * 200) {
  if (fileSize > 10**6 * 1) {
    return false;
  }
  
  return true;
}



export const acceptableUserImageFile = value => {
  const fileType = value.split('.')[value.split('.').length -1].toLowerCase();
  console.log(fileType);
  if (
    fileType === 'png' || 
    fileType === 'jpg' || 
    fileType === 'jpeg'
  ) {
    return true;
  } else {
    return false;
  }
}

export const startEndTimeValidity = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return false;
  }
  
  const start = new Date(startTime).getTime();
  const end = new Date(endTime).getTime();

  if (!start || !end) {
    return false;
  }
  if (start >= end) {
    return false;
  }
  if (start < Date.now()) {
    return false;
  }
  return true;
}

export const targetLocationValidity = (lat, lng, radiusKm) => {
  console.log(radiusKm);
  if (
    !radiusKm 
    || radiusKm === NaN 
    || radiusKm < 1000
    || radiusKm > 20000
  ) {
    return false;
  }
  return true;
}